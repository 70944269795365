import "../../index31.css";
import { jsxs as I, jsx as t } from "react/jsx-runtime";
import { _ as r } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import R from "../InputError/index.js";
import j from "../InputLabel/index.js";
import w from "../../hooks/useBasicLayout/index.js";
const L = "_input-container_1b4m7_1", k = "_input-section_1b4m7_4", A = "_input_1b4m7_1", B = "_disabled_1b4m7_17", T = "_errored_1b4m7_25", W = "_general_1b4m7_31", z = "_read-only_1b4m7_38", D = "_input-hint_1b4m7_53", F = "_input-error_1b4m7_58", n = {
  inputContainer: L,
  inputSection: k,
  input: A,
  disabled: B,
  errored: T,
  general: W,
  readOnly: z,
  inputHint: D,
  inputError: F
};
function V(l) {
  const {
    containerRef: c,
    inputSectionRef: m,
    actions: p,
    className: u,
    disabled: i,
    error: e,
    errorOnTooltip: a = !1,
    hint: o,
    icons: d,
    input: _,
    inputSectionClassName: C,
    label: b,
    labelClassName: f,
    readOnly: N,
    required: h,
    variant: s = "form",
    withAsterisk: y,
    actionsContainerClassName: g,
    errorContainerClassName: S,
    hintContainerClassName: v,
    iconsContainerClassName: O,
    spacing: x
  } = l, E = y ?? h, {
    content: q,
    containerClassName: H
  } = w({
    className: r(n.inputSection, C),
    icons: d,
    iconsContainerClassName: O,
    actions: p,
    actionsContainerClassName: g,
    children: _,
    childrenContainerClassName: n.input,
    spacing: x,
    withoutWrap: !0,
    variant: "xs"
  });
  return /* @__PURE__ */ I(
    "div",
    {
      ref: c,
      className: r(
        n.inputContainer,
        !!e && n.errored,
        N && n.readOnly,
        s === "form" && n.form,
        s === "general" && n.general,
        i && n.disabled,
        u
      ),
      title: a && e && typeof e == "string" ? e : void 0,
      children: [
        /* @__PURE__ */ t(
          j,
          {
            className: f,
            disabled: i,
            required: E,
            children: b
          }
        ),
        /* @__PURE__ */ t(
          "div",
          {
            ref: m,
            className: H,
            children: q
          }
        ),
        o && /* @__PURE__ */ t("div", { className: r(n.inputHint, v), children: o }),
        !a && /* @__PURE__ */ t(
          R,
          {
            disabled: i,
            className: r(n.inputError, S),
            children: e
          }
        )
      ]
    }
  );
}
export {
  V as default
};
