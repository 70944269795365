import { useState as a, useEffect as d } from "react";
function V(e, c, u) {
  const [t, l] = a(
    () => u ? u(e) : e
  );
  return d(() => {
    const o = setTimeout(() => {
      l(u ? u(e) : e);
    }, c ?? 300);
    return () => {
      clearTimeout(o);
    };
  }, [e, c, u]), t;
}
export {
  V as default
};
