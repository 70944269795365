import "../../index16.css";
import { jsxs as f, jsx as o } from "react/jsx-runtime";
import { _ as n } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import u from "../Portal/index.js";
import h from "../../hooks/useFloatPlacement.js";
const v = "_popup_cws49_1", w = "_top-orientation_cws49_9", N = "_pointer_cws49_15", x = "_icon_cws49_25", t = {
  popup: v,
  topOrientation: w,
  pointer: N,
  icon: x
};
function R(i) {
  const {
    parentRef: r,
    elementRef: p,
    children: s,
    className: c,
    pointerClassName: a,
    preferredWidth: l
  } = i, {
    content: m,
    pointer: _,
    width: d,
    orientation: e
  } = h(r, l);
  return /* @__PURE__ */ f(u, { children: [
    /* @__PURE__ */ o(
      "div",
      {
        ref: p,
        style: {
          ...m,
          width: d
        },
        className: n(
          t.popup,
          e.vertical === "bottom" && t.topOrientation,
          c
        ),
        children: s
      }
    ),
    /* @__PURE__ */ o(
      "div",
      {
        className: n(
          t.pointer,
          e.vertical === "bottom" && t.topOrientation,
          a
        ),
        style: { ..._ },
        children: /* @__PURE__ */ o(
          "svg",
          {
            className: t.icon,
            viewBox: "0 0 200 100",
            children: /* @__PURE__ */ o(
              "path",
              {
                d: "M0 100 L100 0 L200 100Z"
              }
            )
          }
        )
      }
    )
  ] });
}
export {
  R as default
};
