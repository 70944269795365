import "../../index28.css";
import { jsxs as r, jsx as c } from "react/jsx-runtime";
import { useRef as d } from "react";
import { _ as u } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import x from "../../hooks/useSizeTracking.js";
const a = "_heading_1uxwj_1", p = "_level-one_1uxwj_9", m = "_level-two_1uxwj_17", w = "_level-three_1uxwj_25", h = "_level-four_1uxwj_33", f = "_level-five_1uxwj_37", T = "_level-six_1uxwj_41", j = "_ellipsized-text_1uxwj_45", z = "_ellipsized_1uxwj_45", e = {
  heading: a,
  levelOne: p,
  levelTwo: m,
  levelThree: w,
  levelFour: h,
  levelFive: f,
  levelSix: T,
  ellipsizedText: j,
  ellipsized: z
}, g = {
  1: e.levelOne,
  2: e.levelTwo,
  3: e.levelThree,
  4: e.levelFour,
  5: e.levelFive,
  6: e.levelSix
};
function R(o) {
  const {
    className: n,
    level: s = 3,
    children: l,
    ellipsize: i
  } = o, v = `h${s}`, t = d(null), _ = x(t);
  return l ? /* @__PURE__ */ r(
    v,
    {
      className: u(
        e.heading,
        i && e.ellipsized,
        g[s],
        n
      ),
      ref: t,
      children: [
        i && /* @__PURE__ */ c(
          "div",
          {
            className: e.ellipsizedText,
            style: {
              width: `${_.width}px`
            },
            title: typeof l == "string" ? l : void 0,
            children: l
          }
        ),
        !i && l
      ]
    }
  ) : null;
}
export {
  R as default
};
