import "../../index11.css";
import { jsx as o, jsxs as u } from "react/jsx-runtime";
import { useContext as d, useRef as f, useEffect as p, useCallback as C } from "react";
import { _ } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import A from "../Alert/index.js";
import b from "../Portal/index.js";
import D from "../../contexts/alert.js";
import { DURATION_DEFAULT_ALERT_DISMISS as T } from "../../utils/constants.js";
const h = "_alert-container_rcayz_1", x = "_alert_rcayz_1", s = {
  alertContainer: h,
  alert: x
};
function R(i) {
  const {
    className: a,
    children: m
  } = i, {
    alerts: n,
    removeAlert: r
  } = d(D), t = f({});
  p(
    () => {
      n.filter((e) => !e.nonDismissable).forEach((e) => {
        t.current[e.name] || (t.current[e.name] = window.setTimeout(
          () => {
            r(e.name), delete t.current[e.name];
          },
          e.duration ?? T
        ));
      });
    },
    [n, r]
  );
  const c = C(
    (e) => {
      const l = t.current[e];
      window.clearTimeout(l), r(e), delete t.current[e];
    },
    [r]
  );
  return /* @__PURE__ */ o(b, { children: /* @__PURE__ */ u("div", { className: _(s.alertContainer, a), children: [
    n.map((e) => /* @__PURE__ */ o(
      A,
      {
        name: e.name,
        className: s.alert,
        nonDismissable: e.nonDismissable,
        type: e.variant,
        onCloseButtonClick: c,
        debugMessage: e.debugMessage,
        title: e.title,
        description: e.description
      },
      e.name
    )),
    m
  ] }) });
}
export {
  R as default
};
