function r(a, t) {
  const n = a % t;
  return n < 0 ? t + n : Math.abs(n);
}
function m(a) {
  return a.reduce((t, n) => t + n, 0);
}
function s(a, t, n) {
  const u = Math.min(t, n), o = Math.max(t, n);
  return Math.max(u, Math.min(o, a));
}
export {
  s as b,
  r as m,
  m as s
};
