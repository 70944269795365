function t(e) {
  return typeof e == "number" ? Number.isNaN(e) : !1;
}
function r(e) {
  return e == null || t(e);
}
function o(e) {
  return !r(e);
}
function i(e) {
  let n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
  return r(e) || e === !1 || n.includes(e);
}
function u(e) {
  return i(e, [""]);
}
function a(e) {
  return !u(e);
}
const s = ["Boolean", "Number", "String", "Function", "Array", "Date", "RegExp", "Object", "Error", "Symbol"];
s.reduce((e, n) => ({
  ...e,
  [`[object ${n}]`]: n.toLowerCase()
}), {});
export {
  o as a,
  a as b,
  u as c,
  r as i
};
