import "../../index14.css";
import { jsx as l } from "react/jsx-runtime";
import { _ as n } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
const c = "_overlay_cnygi_1", i = "_dark_cnygi_7", r = {
  overlay: c,
  dark: i
};
function v(a) {
  const {
    children: e,
    className: o,
    elementRef: t,
    variant: s = "light"
  } = a;
  return /* @__PURE__ */ l(
    "div",
    {
      ref: t,
      className: n(
        o,
        r.overlay,
        s === "dark" && r.dark
      ),
      children: e
    }
  );
}
export {
  v as default
};
