import { i as f } from "./type-BFTbooL7.js";
function d(t, s, e) {
  return f(t) ? void 0 : t.reduce((n, r, u) => {
    const o = s(r, u);
    return n[o] = e ? e(r, o, u, n) : r, n;
  }, {});
}
export {
  d as l
};
