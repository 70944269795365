import { createContext as e } from "react";
const l = e({
  alerts: [],
  // eslint-disable-next-line no-console
  addAlert: () => {
    console.warn("AlertContext::addAlert called before it was initialized");
  },
  // eslint-disable-next-line no-console
  removeAlert: () => {
    console.warn("AlertContext::removeAlert called before it was initialized");
  },
  // eslint-disable-next-line no-console
  updateAlert: () => {
    console.warn("AlertContext::updateAlert called before it was initialized");
  }
});
export {
  l as default
};
