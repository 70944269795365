import { jsx as a } from "react/jsx-runtime";
import i from "react";
import { TabContext as c } from "../TabContext.js";
function u(t) {
  const e = i.useContext(c), {
    name: o,
    elementRef: n,
    ...r
  } = t;
  return e.activeTab === o ? /* @__PURE__ */ a(
    "div",
    {
      ...r,
      role: "tabpanel",
      ref: n
    }
  ) : null;
}
export {
  u as default
};
