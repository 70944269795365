import { i } from "./type-BFTbooL7.js";
function h(n, e) {
  if (i(n))
    return;
  const o = {}, r = [];
  return n.forEach((u) => {
    const f = e ? e(u) : JSON.stringify(u);
    o[f] || (o[f] = !0, r.push(u));
  }), n.length === r.length ? n : r;
}
export {
  h as u
};
