import "../../../index85.css";
import { jsx as r, jsxs as m, Fragment as T } from "react/jsx-runtime";
import d from "react";
import { CloseFillIcon as B, CheckFillIcon as x } from "@ifrc-go/icons";
import { _ as E } from "../../../cs-piwRuCFZ.js";
import "../../../type-BFTbooL7.js";
import F from "../../RawButton/index.js";
import { TabContext as S } from "../TabContext.js";
import W from "../../../hooks/useTranslation.js";
const j = "common", w = {
  buttonTitleTab: "Tab"
}, A = {
  namespace: j,
  strings: w
}, I = "_tab_12kk2_1", P = "_dummy_12kk2_12", R = "_children-wrapper_12kk2_18", q = "_vertical-compact_12kk2_26", z = "_vertical_12kk2_26", D = "_active_12kk2_35", G = "_primary_12kk2_48", H = "_active-border_12kk2_69", J = "_step_12kk2_75", K = "_visual-elements_12kk2_85", L = "_step-circle_12kk2_90", M = "_inner-circle_12kk2_98", O = "_icon_12kk2_108", Q = "_progress-bar-end_12kk2_115", U = "_progress-bar-start_12kk2_116", V = "_errored_12kk2_128", X = "_completed_12kk2_154", Y = "_secondary_12kk2_183", Z = "_tertiary_12kk2_193", $ = "_disabled_12kk2_205", e = {
  tab: I,
  dummy: P,
  childrenWrapper: R,
  verticalCompact: q,
  vertical: z,
  active: D,
  primary: G,
  activeBorder: H,
  step: J,
  visualElements: K,
  stepCircle: L,
  innerCircle: M,
  icon: O,
  progressBarEnd: Q,
  progressBarStart: U,
  errored: V,
  completed: X,
  secondary: Y,
  tertiary: Z,
  disabled: $
};
function le(b) {
  const a = d.useContext(S), y = W(A), {
    variant: t,
    disabled: u,
    registerTab: p,
    unregisterTab: _,
    setStep: c,
    step: C
  } = a, {
    className: f,
    name: s,
    step: o = 0,
    disabled: g,
    children: i,
    errored: n,
    ...h
  } = b;
  d.useEffect(() => (p(s), () => {
    _(s);
  }), [p, _, s]);
  const l = a.activeTab === s;
  d.useEffect(() => {
    l && c && c(o);
  }, [l, c, o]);
  const k = C > o, N = /* @__PURE__ */ m(T, { children: [
    t === "step" && /* @__PURE__ */ m("div", { className: e.visualElements, children: [
      /* @__PURE__ */ r("div", { className: e.progressBarStart }),
      /* @__PURE__ */ r("div", { className: e.stepCircle, children: /* @__PURE__ */ m("div", { className: e.innerCircle, children: [
        n && /* @__PURE__ */ r(B, { className: e.icon }),
        !n && k && /* @__PURE__ */ r(x, { className: e.icon })
      ] }) }),
      /* @__PURE__ */ r("div", { className: e.progressBarEnd })
    ] }),
    t === "primary" && /* @__PURE__ */ r("div", { className: e.dummy }),
    /* @__PURE__ */ r("div", { className: e.childrenWrapper, children: i }),
    t === "primary" && /* @__PURE__ */ r("div", { className: e.dummy })
  ] }), v = u || g;
  return /* @__PURE__ */ r(
    F,
    {
      className: E(
        e.tab,
        l && e.active,
        v && e.disabled,
        t === "primary" && e.primary,
        t === "secondary" && e.secondary,
        t === "tertiary" && e.tertiary,
        t === "step" && e.step,
        t === "vertical" && e.vertical,
        t === "vertical-compact" && e.verticalCompact,
        k && e.completed,
        n && e.errored,
        f
      ),
      onClick: a.setActiveTab,
      name: s,
      disabled: v,
      type: "button",
      title: typeof i == "string" ? i : y.buttonTitleTab,
      ...h,
      children: N
    }
  );
}
export {
  le as default
};
