import { i as a } from "./type-BFTbooL7.js";
function u(t, e) {
  let r = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "0";
  if (a(t))
    return "";
  const n = String(t);
  return n.length >= e ? n : Array(e - n.length + 1).join(r) + n;
}
function g() {
  let t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 16, e = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !1, r = "";
  const n = e ? "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" : "abcdefghijklmnopqrstuvwxyz0123456789";
  for (let s = 0; s < t; s += 1)
    r += n.charAt(Math.floor(Math.random() * n.length));
  return r;
}
function o(t, e) {
  return a(t) || a(e) ? !1 : String(t).trim().toLowerCase().includes(String(e).trim().toLowerCase());
}
export {
  o as c,
  u as p,
  g as r
};
