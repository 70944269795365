import { useState as r, useCallback as t } from "react";
function f(s) {
  const [a, e] = r(!!s), o = t(() => e(!0), []), u = t(() => e(!1), []), l = t(() => e((n) => !n), []);
  return [
    a,
    {
      setValue: e,
      setTrue: o,
      setFalse: u,
      toggle: l
    }
  ];
}
export {
  f as default
};
