import { createContext as e } from "react";
const t = e({
  currentLanguage: "en",
  setCurrentLanguage: () => {
    console.warn("LanguageContext::setCurrentLanguage called without a provider");
  },
  languageNamespaceStatus: {},
  setLanguageNamespaceStatus: () => {
    console.warn("LanguageContext::setLanguageNamespaceStatus called without a provider");
  },
  strings: {},
  setStrings: () => {
    console.warn("LanguageContext::setStrings called without a provider");
  },
  registerNamespace: () => {
    console.warn("LanguageContext::registerNamespace called without a provider");
  }
});
export {
  t as default
};
