import { jsx as n, Fragment as a } from "react/jsx-runtime";
import { createPortal as c } from "react-dom";
function m(r) {
  const {
    children: t,
    container: o = document.body,
    portalKey: e
  } = r;
  return /* @__PURE__ */ n(a, { children: c(
    t,
    o,
    e
  ) });
}
export {
  m as default
};
