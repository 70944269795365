import "../../index33.css";
import { jsxs as d, jsx as t } from "react/jsx-runtime";
import { _ as n } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
const u = "_input-label_3lsx4_1", c = "_disabled_3lsx4_7", o = "_required_3lsx4_11", e = {
  inputLabel: u,
  disabled: c,
  required: o
};
function x(i) {
  const {
    children: s,
    className: r,
    disabled: l,
    required: a
  } = i;
  return s ? /* @__PURE__ */ d(
    "div",
    {
      className: n(
        e.inputLabel,
        l && e.disabled,
        r
      ),
      children: [
        s,
        a && /* @__PURE__ */ t("span", { "aria-hidden": !0, className: e.required, children: "*" })
      ]
    }
  ) : null;
}
export {
  x as default
};
