import { jsx as n } from "react/jsx-runtime";
import h from "../InputContainer/index.js";
import C from "../RawInput/index.js";
function O(o) {
  const {
    actions: a,
    className: i,
    disabled: t,
    error: s,
    errorOnTooltip: p,
    hint: u,
    icons: l,
    inputClassName: m,
    inputSectionClassName: c,
    label: d,
    readOnly: r,
    required: e,
    variant: f,
    withAsterisk: x,
    type: I = "text",
    ...N
  } = o;
  return /* @__PURE__ */ n(
    h,
    {
      className: i,
      actions: a,
      disabled: t,
      error: s,
      errorOnTooltip: p,
      hint: u,
      icons: l,
      inputSectionClassName: c,
      label: d,
      required: e,
      readOnly: r,
      variant: f,
      withAsterisk: x,
      input: /* @__PURE__ */ n(
        C,
        {
          ...N,
          required: e,
          readOnly: r,
          disabled: t,
          className: m,
          type: I
        }
      )
    }
  );
}
export {
  O as default
};
