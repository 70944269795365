import "../../index15.css";
import { jsx as r } from "react/jsx-runtime";
import { _ as e } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import l from "../Overlay/index.js";
import s from "../Portal/index.js";
const m = "_body-overlay_1iouu_1", y = {
  bodyOverlay: m
};
function f(o) {
  const {
    className: a,
    children: t
  } = o;
  return /* @__PURE__ */ r(s, { children: /* @__PURE__ */ r(
    l,
    {
      variant: "dark",
      className: e(a, y.bodyOverlay),
      children: t
    }
  ) });
}
export {
  f as default
};
