import "../../index27.css";
import { jsxs as m, Fragment as w, jsx as a } from "react/jsx-runtime";
import { useMemo as y } from "react";
import { i as d } from "../../type-BFTbooL7.js";
import { _ as z } from "../../cs-piwRuCFZ.js";
import L from "../Heading/index.js";
import k from "../../hooks/useBasicLayout/index.js";
import B from "../../hooks/useSpacingTokens.js";
const F = "_header_537v1_1", M = {
  header: F
};
function I(h) {
  const {
    actions: p,
    actionsContainerClassName: C,
    children: n,
    childrenContainerClassName: N,
    className: f,
    elementRef: g,
    ellipsizeHeading: r,
    heading: i,
    headingClassName: t,
    headingLevel: o,
    headingDescription: e,
    headingDescriptionContainerClassName: l,
    icons: u,
    iconsContainerClassName: v,
    headingSectionClassName: _,
    headingContainerClassName: H,
    wrapHeadingContent: x = !1,
    spacing: c = "default"
  } = h, D = y(
    () => d(i) && d(e) ? null : /* @__PURE__ */ m(w, { children: [
      /* @__PURE__ */ a(
        L,
        {
          level: o,
          className: t,
          ellipsize: r,
          children: i
        }
      ),
      e && /* @__PURE__ */ a("div", { className: l, children: e })
    ] }),
    [
      i,
      r,
      e,
      t,
      l,
      o
    ]
  ), {
    content: s,
    containerClassName: S
  } = k({
    actions: p,
    actionsContainerClassName: C,
    children: D,
    childrenContainerClassName: H,
    className: _,
    icons: u,
    iconsContainerClassName: v,
    withoutWrap: !x,
    spacing: c,
    variant: "sm"
  }), j = B({
    spacing: c,
    inner: !0
  });
  return !s && !n ? null : /* @__PURE__ */ m(
    "div",
    {
      className: z(
        M.header,
        j,
        f
      ),
      ref: g,
      children: [
        s && /* @__PURE__ */ a("div", { className: S, children: s }),
        n && /* @__PURE__ */ a("div", { className: N, children: n })
      ]
    }
  );
}
export {
  I as default
};
