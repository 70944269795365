import "../../index25.css";
import { jsxs as m, jsx as t, Fragment as A } from "react/jsx-runtime";
import { useRef as p, useState as F, useEffect as P, useCallback as u, useMemo as j } from "react";
import { ArrowUpSmallFillIcon as y, ArrowDownSmallFillIcon as E } from "@ifrc-go/icons";
import { _ as f } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import W from "../Button/index.js";
import g from "../Popup/index.js";
import U from "../../contexts/dropdown-menu.js";
import V from "../../hooks/useBlurEffect.js";
const q = "_dropdown-menu_16hml_1", z = "_icons_16hml_4", G = "_content_16hml_5", H = "_actions_16hml_6", J = "_dropdown-icon_16hml_10", K = "_dropdown-content_16hml_16", n = {
  dropdownMenu: q,
  icons: z,
  content: G,
  actions: H,
  dropdownIcon: J,
  dropdownContent: K
};
function eo(w) {
  const _ = p(null), {
    className: h,
    popupClassName: C,
    children: N,
    label: R,
    activeClassName: I,
    icons: M,
    variant: v = "secondary",
    actions: a,
    withoutDropdownIcon: l,
    componentRef: r,
    elementRef: s = _,
    persistent: i,
    preferredPopupWidth: D
  } = w, d = p(null), [e, o] = F(!1);
  P(() => {
    r && (r.current = {
      setShowDropdown: o
    });
  }, [r, o]);
  const x = u(
    () => {
      o((c) => !c);
    },
    [o]
  ), b = u(
    (c, S) => {
      S || c && i || o(!1);
    },
    [o, i]
  );
  V(
    e,
    b,
    d,
    s
  );
  const k = j(
    () => ({
      setShowDropdown: o
    }),
    [o]
  ), B = !!a || !l;
  return /* @__PURE__ */ m(U.Provider, { value: k, children: [
    /* @__PURE__ */ t(
      W,
      {
        name: void 0,
        className: f(
          n.dropdownMenu,
          e && I,
          h
        ),
        elementRef: s,
        onClick: x,
        variant: v,
        actionsContainerClassName: n.actions,
        iconsContainerClassName: n.icons,
        childrenContainerClassName: n.content,
        actions: B ? /* @__PURE__ */ m(A, { children: [
          a,
          !l && (e ? /* @__PURE__ */ t(y, { className: n.dropdownIcon }) : /* @__PURE__ */ t(E, { className: n.dropdownIcon }))
        ] }) : void 0,
        icons: M,
        children: R
      }
    ),
    e && /* @__PURE__ */ t(
      g,
      {
        elementRef: d,
        className: f(n.dropdownContent, C),
        parentRef: s,
        preferredWidth: D,
        children: N
      }
    )
  ] });
}
export {
  eo as default
};
