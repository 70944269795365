import "../../index67.css";
import { jsxs as g, Fragment as y, jsx as s } from "react/jsx-runtime";
import { i as e } from "../../type-BFTbooL7.js";
import { _ as a } from "../../cs-piwRuCFZ.js";
import m from "../useSpacingTokens.js";
const j = "_basic-layout_v9jro_1", w = "_with-wrap_v9jro_5", b = "_icons-container_v9jro_9", L = "_children-container_v9jro_14", W = "_actions-container_v9jro_19", n = {
  basicLayout: j,
  withWrap: w,
  iconsContainer: b,
  childrenContainer: L,
  actionsContainer: W
};
function G(p) {
  const {
    className: d,
    icons: o,
    children: c,
    actions: t,
    iconsContainerClassName: C,
    childrenContainerClassName: _,
    actionsContainerClassName: h,
    withoutWrap: u,
    spacing: r = "default",
    variant: l = "md"
  } = p, v = m({
    spacing: r,
    variant: l,
    mode: "gap"
  }), i = m({
    spacing: r,
    variant: l,
    mode: "gap",
    inner: !0
  }), N = a(
    n.basicLayout,
    !u && n.withWrap,
    v,
    d
  ), f = e(o) && e(c) && e(t) ? null : /* @__PURE__ */ g(y, { children: [
    o && /* @__PURE__ */ s(
      "div",
      {
        className: a(
          n.iconsContainer,
          i,
          C
        ),
        children: o
      }
    ),
    /* @__PURE__ */ s(
      "div",
      {
        className: a(
          n.childrenContainer,
          i,
          _
        ),
        children: c
      }
    ),
    t && /* @__PURE__ */ s(
      "div",
      {
        className: a(
          n.actionsContainer,
          i,
          h
        ),
        children: t
      }
    )
  ] });
  return {
    containerClassName: N,
    content: f
  };
}
export {
  G as default
};
