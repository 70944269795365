import { jsx as f } from "react/jsx-runtime";
import { i as c } from "../../type-BFTbooL7.js";
function u(o) {
  const {
    data: r,
    keySelector: s,
    renderer: a,
    rendererParams: i
  } = o;
  return c(r) ? null : r.map(
    (e, n) => {
      const t = s(e, n), d = i(t, e, n, r);
      return /* @__PURE__ */ f(
        a,
        {
          ...d
        },
        String(t)
      );
    }
  );
}
export {
  u as default
};
