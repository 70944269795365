import { useState as C, useCallback as $, useEffect as P } from "react";
import { i as X, a as L } from "../type-BFTbooL7.js";
import { b as S } from "../statistics-IyDYgeE6.js";
const d = parseFloat(getComputedStyle(document.documentElement).fontSize), W = 16 * d, g = 0.5 * d, F = {
  vertical: "bottom",
  horizontal: "right"
};
function H(t) {
  const r = window.innerWidth / 2, u = window.innerHeight / 2, h = t.x + t.width / 2, e = t.y + t.height / 2;
  return {
    horizontal: h < r ? "left" : "right",
    vertical: e < u ? "top" : "bottom"
  };
}
const b = {
  top: "unset",
  left: "unset",
  right: "unset",
  bottom: "unset"
};
function I(t, r) {
  const [u, h] = C({
    content: b,
    pointer: b,
    width: "auto",
    orientation: F
  }), e = $(() => {
    if (X(t.current))
      return;
    const s = t.current.getBoundingClientRect(), {
      x: a,
      y: l,
      width: x,
      height: v
    } = s, c = d, w = c, m = window.innerWidth - c, z = window.innerWidth - 2 * c, n = H(s), f = a + x / 2, E = S(
      L(r) ? r * d : x,
      W,
      z
    );
    let o = f - E / 2, i = f + E / 2;
    if (o < w) {
      const p = w - o - c;
      o = w, i += p;
    }
    if (i > m) {
      const p = i - m - c;
      i = m, o -= p;
    }
    h({
      content: {
        bottom: n.vertical === "bottom" ? `${window.innerHeight - l + g}px` : "unset",
        top: n.vertical === "top" ? `${l + v + g}px` : "unset",
        left: n.horizontal === "left" ? `${o}px` : "unset",
        right: n.horizontal === "right" ? `${window.innerWidth - i}px` : "unset"
      },
      pointer: {
        left: `${f}px`,
        top: n.vertical === "top" ? `${l + v}px` : `${l - g}px`,
        right: "unset",
        bottom: "unset"
      },
      width: `${i - o}px`,
      orientation: n
    });
  }, [t, r]);
  return P(() => {
    e();
    const s = e, a = e;
    return window.addEventListener("scroll", s, !0), window.addEventListener("resize", a, !0), () => {
      window.removeEventListener("scroll", s, !0), window.removeEventListener("resize", a, !0);
    };
  }, [e]), u;
}
export {
  I as default
};
