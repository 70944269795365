import "../../index9.css";
import { jsx as d } from "react/jsx-runtime";
import p from "react";
import { _ } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
const b = "_raw-button_1p3om_1", w = "_focused_1p3om_13", e = {
  rawButton: b,
  focused: w
};
function R(s) {
  const {
    children: n,
    className: a,
    disabled: c,
    elementRef: r,
    name: o,
    onClick: t,
    focused: u,
    type: f = "button",
    ...i
  } = s, m = p.useCallback(
    (l) => {
      t && t(o, l);
    },
    [t, o]
  );
  return /* @__PURE__ */ d(
    "button",
    {
      ref: r,
      name: typeof o == "string" ? o : void 0,
      type: f,
      className: _(
        e.rawButton,
        u && e.focused,
        a
      ),
      disabled: c,
      onClick: t ? m : void 0,
      ...i,
      children: n
    }
  );
}
export {
  R as default
};
