import f from "react";
function m(r, c, o, s) {
  f.useEffect(
    () => {
      if (!r)
        return;
      const a = (t) => {
        const { current: n } = o, { current: e } = s, i = t.target, u = t && n ? n === t.target || n.contains(i) : !1, d = e ? e === t.target || e.contains(i) : !1;
        c(u, d, t);
      };
      return document.addEventListener("click", a), () => {
        document.removeEventListener("click", a);
      };
    },
    [r, c, o, s]
  );
}
export {
  m as default
};
