import "../../index10.css";
import { jsx as N } from "react/jsx-runtime";
import { useMemo as h, useCallback as w } from "react";
import { i as z } from "../../type-BFTbooL7.js";
import { _ as y } from "../../cs-piwRuCFZ.js";
import k from "../RawButton/index.js";
import v from "../../hooks/useBasicLayout/index.js";
const x = "_button_18a84_1", B = "_ellipsized_18a84_29", D = "_children_18a84_32", W = "_overflow-wrapper_18a84_36", I = "_primary_18a84_44", M = "_secondary_18a84_45", O = "_no-spacing_18a84_50", P = "_condensed-spacing_18a84_54", T = "_compact-spacing_18a84_58", F = "_cozy-spacing_18a84_62", j = "_default-spacing_18a84_66", L = "_comfortable-spacing_18a84_70", R = "_relaxed-spacing_18a84_74", V = "_loose-spacing_18a84_78", q = "_disabled_18a84_88", A = "_tertiary_18a84_116", E = "_tertiary-on-dark_18a84_130", G = "_dropdown-item_18a84_144", a = {
  button: x,
  ellipsized: B,
  children: D,
  overflowWrapper: W,
  primary: I,
  secondary: M,
  noSpacing: O,
  condensedSpacing: P,
  compactSpacing: T,
  cozySpacing: F,
  defaultSpacing: j,
  comfortableSpacing: L,
  relaxedSpacing: R,
  looseSpacing: V,
  disabled: q,
  tertiary: A,
  tertiaryOnDark: E,
  dropdownItem: G
}, H = {
  primary: a.primary,
  secondary: a.secondary,
  tertiary: a.tertiary,
  "tertiary-on-dark": a.tertiaryOnDark,
  "dropdown-item": a.dropdownItem
}, J = {
  none: a.noSpacing,
  condensed: a.condensedSpacing,
  compact: a.compactSpacing,
  cozy: a.cozySpacing,
  default: a.defaultSpacing,
  comfortable: a.comfortableSpacing,
  relaxed: a.relaxedSpacing,
  loose: a.looseSpacing
};
function K(c) {
  const {
    actions: i,
    actionsContainerClassName: r,
    children: o,
    childrenContainerClassName: l,
    className: p,
    disabled: s,
    icons: d,
    iconsContainerClassName: m,
    variant: _ = "primary",
    spacing: n = "default",
    ellipsize: e
  } = c, t = y(
    a.button,
    H[_],
    J[n],
    s && a.disabled,
    e && a.ellipsized,
    p
  ), C = h(
    () => e ? /* @__PURE__ */ N("div", { className: a.overflowWrapper, children: o }) : o,
    [e, o]
  ), {
    content: g,
    containerClassName: u
  } = v({
    className: t,
    icons: d,
    children: C,
    actions: i,
    iconsContainerClassName: m,
    childrenContainerClassName: y(a.children, l),
    actionsContainerClassName: r,
    spacing: n,
    withoutWrap: !0,
    variant: "xs"
  });
  return {
    className: u,
    children: g,
    disabled: s
  };
}
function na(c) {
  const {
    actions: i,
    actionsContainerClassName: r,
    children: o,
    childrenContainerClassName: l,
    className: p,
    disabled: s,
    icons: d,
    iconsContainerClassName: m,
    name: _,
    onClick: n,
    variant: e,
    type: t = "button",
    spacing: C,
    ...g
  } = c, u = w((S, b) => {
    n && n(S, b);
  }, [n]), f = K({
    variant: e,
    className: p,
    actionsContainerClassName: r,
    iconsContainerClassName: m,
    childrenContainerClassName: l,
    children: o,
    icons: d,
    actions: i,
    spacing: C,
    // NOTE: disabling a button if there is on onClick handler
    disabled: s || t !== "submit" && z(n)
  });
  return /* @__PURE__ */ N(
    k,
    {
      name: _,
      type: t,
      onClick: u,
      ...g,
      ...f
    }
  );
}
export {
  na as default,
  K as useButtonFeatures
};
