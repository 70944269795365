import { useCallback as o } from "react";
import { a as x } from "../type-BFTbooL7.js";
import { m as c } from "../statistics-IyDYgeE6.js";
const P = [
  40,
  38,
  13,
  8
  /* Backspace */
];
function y(n, t, e) {
  return t.findIndex((f, d) => e(f, d) === n);
}
function v(n, t, e, f) {
  if (e.length <= 0)
    return;
  let l = y(n, e, f);
  l === -1 && (l = t > 0 ? -1 : 0);
  const s = c(l + t, e.length);
  return f(e[s], s);
}
function k(n, t, e, f, d, l, s, m, p) {
  return o(
    (a) => {
      const { keyCode: i } = a, u = n == null ? void 0 : n.key;
      if (f && (i === 9 || i === 27))
        l();
      else if (!f && P.includes(i))
        a.stopPropagation(), a.preventDefault(), s();
      else if (i === 13)
        if (x(u)) {
          a.stopPropagation(), a.preventDefault();
          const r = e.find(
            (D, g) => t(D, g) === u
          );
          r && m(u, r);
        } else
          a.stopPropagation(), a.preventDefault(), p && p();
      else if (i === 40) {
        a.stopPropagation(), a.preventDefault();
        const r = v(u, 1, e, t);
        d(r ? { key: r } : void 0);
      } else if (i === 38) {
        a.stopPropagation(), a.preventDefault();
        const r = v(u, -1, e, t);
        d(r ? { key: r } : void 0);
      }
    },
    [
      n,
      f,
      t,
      d,
      l,
      m,
      s,
      p,
      e
    ]
  );
}
export {
  k as default
};
