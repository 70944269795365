import { jsxs as f, Fragment as l, jsx as u } from "react/jsx-runtime";
import { Fragment as a } from "react";
import { i as s } from "../type-BFTbooL7.js";
function x(o, i) {
  return s(o) ? "" : o.split("{").map((r) => {
    const e = r.indexOf("}");
    if (e === -1)
      return r;
    const n = r.substring(0, e), t = i[n];
    return s(t) ? (console.error(`resolveToString: value for key "${n}" not provided`), "") : r.replace(`${n}}`, String(t));
  }).join("");
}
const m = {};
function y(o, i = m) {
  if (s(o))
    return "";
  const c = o.split("{").map((r) => {
    const e = r.indexOf("}");
    if (e === -1)
      return r;
    const n = r.substring(0, e), t = i[n];
    return s(t) ? (console.error(`resolveToComponent: value for key "${n}" not provided`), null) : /* @__PURE__ */ f(l, { children: [
      t,
      r.replace(`${n}}`, "")
    ] });
  });
  return /* @__PURE__ */ u(l, { children: c.map((r, e) => (
    // eslint-disable-next-line react/no-array-index-key
    /* @__PURE__ */ u(a, { children: r }, e)
  )) });
}
export {
  y as resolveToComponent,
  x as resolveToString
};
