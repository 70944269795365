import { createContext as e } from "react";
const i = e({
  tabs: [],
  step: 0,
  disabled: !1,
  activeTab: "",
  variant: "primary",
  // eslint-disable-next-line no-console
  setActiveTab: () => {
    console.warn("setActiveTab called before it was initialized");
  },
  // eslint-disable-next-line no-console
  registerTab: () => {
    console.warn("registerTab called before it was initialized");
  },
  // eslint-disable-next-line no-console
  unregisterTab: () => {
    console.warn("unregisterTab called before it was initialized");
  }
});
export {
  i as TabContext
};
