import { jsxs as c, Fragment as m, jsx as o } from "react/jsx-runtime";
import { useState as b, useCallback as l } from "react";
import r from "../Button/index.js";
import O from "../Modal/index.js";
import y from "../../hooks/useTranslation.js";
const M = "common", w = {
  confirmation: "Confirmation",
  confirmMessage: "Are you sure you want to continue?",
  buttonCancel: "Cancel",
  buttonOk: "Ok"
}, x = {
  namespace: M,
  strings: w
};
function z(f) {
  const n = y(x), {
    confirmHeading: u = n.confirmation,
    confirmMessage: C = n.confirmMessage,
    name: s,
    onConfirm: a,
    onClick: t,
    ...d
  } = f, [p, i] = b(!1), h = l(
    (e) => {
      i(!1), a(e);
    },
    [a]
  ), k = l(
    (e, g) => {
      t && t(e, g), i(!0);
    },
    [t]
  );
  return /* @__PURE__ */ c(m, { children: [
    /* @__PURE__ */ o(
      r,
      {
        ...d,
        name: s,
        onClick: k
      }
    ),
    p && /* @__PURE__ */ o(
      O,
      {
        heading: u,
        closeOnEscape: !1,
        size: "sm",
        footerActions: /* @__PURE__ */ c(m, { children: [
          /* @__PURE__ */ o(
            r,
            {
              name: !1,
              onClick: i,
              variant: "secondary",
              children: n.buttonCancel
            }
          ),
          /* @__PURE__ */ o(
            r,
            {
              name: s,
              variant: "primary",
              onClick: h,
              children: n.buttonOk
            }
          )
        ] }),
        children: C
      }
    )
  ] });
}
export {
  z as default
};
