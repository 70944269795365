import "../../../index63.css";
import { jsxs as d, jsx as a } from "react/jsx-runtime";
import l from "react";
import { _ as n } from "../../../cs-piwRuCFZ.js";
import "../../../type-BFTbooL7.js";
import { TabContext as y } from "../TabContext.js";
const p = "_tab-list_1d752_1", v = "_primary_1d752_5", u = "_content_1d752_8", C = "_start-dummy-content_1d752_12", b = "_end-dummy-content_1d752_13", f = "_secondary_1d752_20", x = "_tertiary_1d752_36", D = "_step_1d752_56", N = "_vertical-compact_1d752_70", L = "_vertical_1d752_70", t = {
  tabList: p,
  primary: v,
  content: u,
  startDummyContent: C,
  endDummyContent: b,
  secondary: f,
  tertiary: x,
  step: D,
  verticalCompact: N,
  vertical: L
};
function k(r) {
  const s = l.useContext(y), {
    variant: e,
    disabled: o
  } = s, {
    children: c,
    className: i,
    contentClassName: m,
    ..._
  } = r;
  return /* @__PURE__ */ d(
    "div",
    {
      ..._,
      className: n(
        i,
        t.tabList,
        o && t.disabled,
        e === "primary" && t.primary,
        e === "secondary" && t.secondary,
        e === "tertiary" && t.tertiary,
        e === "step" && t.step,
        e === "vertical" && t.vertical,
        e === "vertical-compact" && t.verticalCompact
      ),
      role: "tablist",
      children: [
        /* @__PURE__ */ a("div", { className: t.startDummyContent }),
        /* @__PURE__ */ a(
          "div",
          {
            className: n(t.content, m),
            children: c
          }
        ),
        /* @__PURE__ */ a("div", { className: t.endDummyContent })
      ]
    }
  );
}
export {
  k as default
};
