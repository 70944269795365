import { createContext as e, useState as S, useMemo as f } from "react";
import { i as t } from "../../type-BFTbooL7.js";
function v(n) {
  if (!t(n))
    return n.direction === "asc" ? n.name : `-${n.name}`;
}
function x(n) {
  const [u, i] = S(n);
  return { sorting: u, setSorting: i };
}
const g = {
  sorting: void 0,
  setSorting: (n) => {
    console.warn("Trying to set to ", n);
  }
}, C = e(g);
function D(n, u, i) {
  const c = f(
    () => {
      const o = u.find((d) => d.id === (n == null ? void 0 : n.name));
      return o == null ? void 0 : o.valueComparator;
    },
    [u, n == null ? void 0 : n.name]
  );
  return f(
    () => t(i) || !c ? i : (n == null ? void 0 : n.direction) === "dsc" ? [...i].sort(c).reverse() : [...i].sort(c),
    [i, c, n == null ? void 0 : n.direction]
  );
}
export {
  C as SortContext,
  D as default,
  v as getOrdering,
  x as useSortState,
  D as useSorting
};
