import { Children as L, createElement as A, Fragment as E } from "react";
import { a as c, i as s, c as y, b as x } from "./type-BFTbooL7.js";
import { p as l, c as O, r as I } from "./string-COe5rUcp.js";
import { l as N } from "./transform-VOqkQszh.js";
import { b as C, s as S } from "./statistics-IyDYgeE6.js";
import { DEFAULT_DATE_FORMAT as H, KEY_DATE_FORMAT as _ } from "./utils/constants.js";
function d(t, e) {
  return function(n, r) {
    let a = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 1;
    const o = c(n) ? t(n) : void 0, i = c(r) ? t(r) : void 0;
    return o === i ? 0 : s(o) ? a * 1 : s(i) ? a * -1 : a * e(o, i);
  };
}
const z = d((t) => t, (t, e) => Number(t) - Number(e)), U = d((t) => t, (t, e) => t.localeCompare(e)), Q = d((t) => t, (t, e) => t - e), X = d((t) => t, (t, e) => {
  const n = new Date(t), r = new Date(e);
  return n.getTime() - r.getTime();
}), j = (t, e, n, r) => n ? d((a) => a, (a, o) => {
  const i = n.toLowerCase(), u = a.toLowerCase().indexOf(i), f = o.toLowerCase().indexOf(i);
  return u === f || u === -1 && f === -1 ? a.localeCompare(o) : f === -1 ? -1 : u === -1 ? 1 : u - f;
})(t, e, r) : 0, P = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], Y = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var m = /* @__PURE__ */ function(t) {
  return t.time = "time", t.date = "date", t;
}(m || {});
function h(t, e) {
  let n, r;
  return e.forEach((a) => {
    const o = t.indexOf(a);
    if (o === -1)
      return;
    const i = o + a.length;
    (n === void 0 || o < n) && (n = o), (r === void 0 || i > r) && (r = i);
  }), {
    start: n,
    end: r
  };
}
function T(t) {
  const {
    start: e,
    end: n
  } = h(t, ["yyyy", "yy", "MMM", "MM", "dd", "EEE"]), {
    start: r,
    end: a
  } = h(t, ["hh", "mm", "ss", "aaa"]), o = t.length, i = (u, f) => u === void 0 || f === void 0 ? "" : t.substring(u, f);
  return e === void 0 && r === void 0 ? [{
    value: t
  }].filter((u) => u.value !== "") : e === void 0 ? [{
    value: i(0, r)
  }, {
    type: m.time,
    value: i(r, a)
  }, {
    value: i(a, o)
  }].filter((u) => u.value !== "") : r === void 0 ? [{
    value: i(0, e)
  }, {
    type: m.date,
    value: i(e, n)
  }, {
    value: i(n, o)
  }].filter((u) => u.value !== "") : e < r ? [{
    value: i(0, e)
  }, {
    type: m.date,
    value: i(e, n)
  }, {
    value: i(n, r)
  }, {
    type: m.time,
    value: i(r, a)
  }, {
    value: i(a, o)
  }].filter((u) => u.value !== "") : [{
    value: i(0, r)
  }, {
    type: m.time,
    value: i(r, a)
  }, {
    value: i(a, e)
  }, {
    type: m.date,
    value: i(e, n)
  }, {
    value: i(n, o)
  }].filter((u) => u.value !== "");
}
function F(t, e) {
  return t.map((n) => {
    if (n.type === m.date) {
      const r = e.getFullYear(), a = e.getMonth(), o = e.getDate(), i = Y[e.getDay()], u = {
        ...n
      };
      return u.value = u.value.replace("yyyy", String(r)).replace("yy", String(r % 100)).replace("MMM", P[a]).replace("MM", l(a + 1, 2)).replace("EEE", i).replace("dd", l(o, 2)), u;
    }
    if (n.type === m.time) {
      const r = n.value.indexOf("aaa"), a = e.getHours(), o = r >= 0 ? (a - 1) % 12 + 1 : a, i = a >= 12 ? "PM" : "AM", u = e.getMinutes(), f = e.getSeconds(), g = {
        ...n
      };
      return g.value = g.value.replace("hh", l(o, 2)).replace("mm", l(u, 2)).replace("ss", l(f, 2)).replace("aaa", i), g;
    }
    return n;
  });
}
function Z(t) {
  if (!s(t))
    return Math.round(t);
}
function tt(t, e, n = !0) {
  if (s(t) || s(e))
    return;
  if (e === 0)
    return 0;
  const r = t * 100 / e;
  return n ? C(r, 0, 100) : r;
}
function D(t) {
  if (s(t))
    return;
  const e = t.filter(c);
  if (e.length !== 0)
    return e;
}
function et(t) {
  const e = D(t);
  if (!s(e))
    return S(e);
}
function nt(t) {
  const e = D(t);
  if (!s(e))
    return Math.max(...e);
}
function rt(t) {
  const e = D(t);
  if (!s(e))
    return Math.min(...e);
}
function at(t) {
  const e = D(t);
  return s(e) ? void 0 : S(e) / e.length;
}
function ot(t, e, n = (r) => r > 1) {
  const r = N(
    t,
    e,
    (a, o, i, u) => {
      const f = u[o];
      return c(f) ? f + 1 : 1;
    }
  );
  return Object.keys(r).filter((a) => n(r[a]));
}
function it(t) {
  return typeof t == "object" && t !== null && !Array.isArray(t);
}
function ut(t, e, n) {
  return y(e) ? t : t.filter((r) => O(n(r), e)).sort((r, a) => j(
    n(r),
    n(a),
    e
  ));
}
function v(t, e, n) {
  return t === 0 ? n ? "" : "0" : `${Intl.NumberFormat("default", { notation: "compact" }).format(t)} ${e}${t !== 1 ? "s" : ""}`;
}
const k = {
  0: {
    shortText: "yr",
    text: "year",
    value: 365 * 24 * 60 * 60
  },
  1: {
    shortText: "mo",
    text: "month",
    value: 30 * 24 * 60 * 60
  },
  2: {
    shortText: "day",
    text: "day",
    value: 24 * 60 * 60
  },
  3: {
    shortText: "hr",
    text: "hour",
    value: 60 * 60
  },
  4: {
    shortText: "min",
    text: "minute",
    value: 60
  },
  5: {
    shortText: "sec",
    text: "second",
    value: 1
  }
};
function p(t, e = " ", n = !1, r = 2, a = 0, o = void 0) {
  if (c(o) && a >= o)
    return "";
  if (a === 5)
    return v(t, n ? "sec" : "second", c(o));
  const i = a + 1, u = k[a], f = Math.floor(t / u.value);
  return f >= 1 ? [
    v(f, n ? u.shortText : u.text, c(o)),
    p(
      t % u.value,
      e,
      n,
      r,
      i,
      o ?? a + r
    )
  ].filter(Boolean).join(" ") : p(
    t,
    e,
    n,
    r,
    i,
    o
  );
}
function st(t, e) {
  const n = e.getTime() - t.getTime(), r = Math.round(n / 1e3);
  return p(r);
}
function ft(t, e) {
  const n = t.toLowerCase().substring(t.lastIndexOf("@") + 1);
  return e.filter((r) => r.is_active).map((r) => r.domain_name.toLowerCase()).includes(n);
}
function M(t) {
  if (t < 1e3)
    return 2;
  const r = new Intl.NumberFormat("default", { notation: "compact" }).formatToParts(t).find(({ type: a }) => a === "fraction");
  return s(r) || y(r.value) ? 0 : Number(r.value) > 0.1 ? 1 : 0;
}
function ct(t, e) {
  if (s(t))
    return;
  const n = {};
  if (s(e))
    return n.maximumFractionDigits = M(t), new Intl.NumberFormat("default", n).format(t);
  const {
    currency: r,
    unit: a,
    maximumFractionDigits: o,
    compact: i,
    separatorHidden: u,
    language: f
  } = e;
  return x(a) && (n.unit = a, n.unitDisplay = "short"), r && (n.currencyDisplay = "narrowSymbol", n.style = "currency"), i && (n.notation = "compact", n.compactDisplay = "short"), n.useGrouping = !u, c(o) ? n.maximumFractionDigits = o : n.maximumFractionDigits = M(t), new Intl.NumberFormat(f, n).format(t);
}
function b(t, e = H) {
  if (s(t))
    return;
  const n = new Date(t);
  return Number.isNaN(n.getTime()) ? void 0 : F(T(e), n).map((o) => o.value).join("");
}
function mt(t) {
  if (s(t))
    return;
  const e = new Date(t);
  return Number.isNaN(e.getTime()) ? void 0 : F(T("yyyy-MM-dd"), e).map((o) => o.value).join("");
}
function lt(t, e, n = !1) {
  const r = t.map(
    (a, o) => e(a, o) ? o : void 0
  ).filter(c);
  return r.length === 0 ? [t] : [...r, t.length].map(
    (a, o) => {
      const i = o === 0 ? 0 : r[o - 1] + (n ? 0 : 1);
      return i === a ? void 0 : t.slice(i, a);
    }
  ).filter(c);
}
function dt(t, e) {
  return new Date(t, e + 1, 0).getDate();
}
function gt(t) {
  return {
    ...t,
    client_id: t.client_id ?? (c(t.id) ? String(t.id) : void 0) ?? I()
  };
}
function Dt() {
  return b(/* @__PURE__ */ new Date(), "MM/yyyy");
}
function pt(t = "long") {
  return Array.from(Array(12).keys()).map(
    (n) => {
      const r = /* @__PURE__ */ new Date();
      return r.setDate(1), r.setMonth(n), r.setHours(0, 0, 0, 0), {
        key: n,
        label: r.toLocaleString(
          "default",
          { month: t }
        )
      };
    }
  );
}
function yt(t, e, n) {
  return t.map((a) => e(a).map((i) => n(a, i))).flat();
}
function w(t) {
  return s(t) ? !1 : typeof t == "boolean" ? !0 : typeof t == "number" ? !Number.isNaN(t) : typeof t == "string" ? x(t.trim()) : Array.isArray(t) ? t.length === 0 ? !1 : t.some(w) : typeof t == "object" && t ? Object.values(t).some(w) : !1;
}
function ht(t, e) {
  const n = L.toArray(t).reduce(
    (r, a, o, i) => (s(a) || (r.push(a), o < i.length - 1 && r.push(e)), r),
    []
  );
  return A(
    E,
    {},
    ...n
  );
}
function vt(t, e) {
  if (!t && !e)
    return !0;
  if (!t || !e || t.length !== e.length)
    return !1;
  if (t.length === 0 && e.length === 0)
    return !0;
  const n = N(
    t,
    (r) => r,
    () => !0
  );
  return e.every((r) => n[r]);
}
const Mt = {
  en: "English",
  fr: "French",
  es: "Spanish",
  ar: "Arabic"
};
function wt(t) {
  if (!y(t))
    return t.includes("T") ? new Date(t).toISOString() : (/* @__PURE__ */ new Date(`${t}T00:00:00`)).toISOString();
}
function xt(t, e) {
  if (s(t) || s(e))
    return;
  const n = new Date(t);
  if (!Number.isNaN(n))
    return n.setDate(1), n.setMonth(
      n.getMonth() + e + 1
    ), n.setDate(0), n.setHours(0, 0, 0, 0), n;
}
function Nt(t) {
  if (s(t))
    return !1;
  const e = new Date(t);
  return !Number.isNaN(e.getTime());
}
function St(t) {
  return b(t, _) ?? "??";
}
function V(t, e = 1) {
  const n = new Date(t);
  return n.setDate(t.getDate() + e), n.setHours(0, 0, 0, 0), n;
}
function Tt(t, e = 1) {
  const n = new Date(t);
  return n.setDate(1), n.setMonth(t.getMonth() + e), n.setHours(0, 0, 0, 0), n;
}
function B(t, e) {
  const n = new Date(t);
  n.setHours(0, 0, 0, 0);
  const r = new Date(e);
  r.setHours(0, 0, 0, 0);
  let a = 0;
  for (let o = n; o < r; o = V(o))
    a += 1;
  return a;
}
function J(t, e) {
  return Math.abs(
    12 * e.getFullYear() + e.getMonth() - (12 * t.getFullYear() + t.getMonth())
  );
}
function Ft(t, e) {
  const n = new Date(t), r = new Date(e), a = r.getFullYear() - n.getFullYear(), o = J(n, r), i = B(n, r);
  return {
    year: a,
    month: o,
    day: i
  };
}
export {
  Nt as A,
  St as B,
  V as C,
  Tt as D,
  B as E,
  J as F,
  Ft as G,
  z as H,
  Q as I,
  U as J,
  X as K,
  rt as a,
  at as b,
  ot as c,
  ut as d,
  st as e,
  ft as f,
  tt as g,
  ct as h,
  it as i,
  b as j,
  mt as k,
  lt as l,
  nt as m,
  dt as n,
  gt as o,
  Dt as p,
  pt as q,
  Z as r,
  et as s,
  yt as t,
  w as u,
  ht as v,
  vt as w,
  Mt as x,
  wt as y,
  xt as z
};
