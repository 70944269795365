import { jsx as C } from "react/jsx-runtime";
import { useState as m, useCallback as x, useMemo as h } from "react";
import { TabContext as v } from "./TabContext.js";
function k(p) {
  const {
    children: T,
    variant: s = "primary",
    disabled: i,
    value: a,
    onChange: c
  } = p, [u, n] = m([]), [d, f] = m(0), l = x((r) => {
    n((t) => t.findIndex((e) => e === r) === -1 ? [...t, r] : t);
  }, [n]), b = x((r) => {
    n((t) => {
      const o = t.findIndex((e) => e === r);
      if (o !== -1) {
        const e = [...t];
        return e.splice(o, 1), e;
      }
      return t;
    });
  }, [n]), g = h(() => ({
    tabs: u,
    variant: s,
    disabled: i,
    activeTab: a,
    setActiveTab: c,
    registerTab: l,
    unregisterTab: b,
    step: d,
    setStep: f
  }), [
    u,
    a,
    c,
    s,
    i,
    l,
    b,
    d,
    f
  ]);
  return /* @__PURE__ */ C(v.Provider, { value: g, children: T });
}
export {
  k as default
};
