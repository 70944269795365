import { useState as f, useEffect as a } from "react";
import { a as r } from "../type-BFTbooL7.js";
import g from "./useDebouncedValue.js";
function d(n, i = !1) {
  const [u, c] = f(() => {
    var t;
    const e = (t = n.current) == null ? void 0 : t.getBoundingClientRect();
    return {
      width: (e == null ? void 0 : e.width) ?? 0,
      height: (e == null ? void 0 : e.height) ?? 0
    };
  });
  return a(() => {
    const e = new ResizeObserver((h) => {
      const o = h.at(0), s = o == null ? void 0 : o.contentRect;
      s && c({
        width: s.width,
        height: s.height
      });
    }), t = n.current;
    return !i && r(t) && e.observe(t), () => {
      !i && r(t) && e.unobserve(t);
    };
  }, [i, n]), g(u);
}
export {
  d as default
};
