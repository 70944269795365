import "../../index78.css";
import { jsx as o } from "react/jsx-runtime";
import { useCallback as a } from "react";
import { CloseLineIcon as f, CheckboxCircleLineIcon as C, ErrorWarningLineIcon as h, InformationLineIcon as I, QuestionLineIcon as k } from "@ifrc-go/icons";
import { _ as w } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import r from "../Button/index.js";
import y from "../Container/index.js";
import N from "../../hooks/useTranslation.js";
const v = "common", b = {
  alertCopyErrorDetails: "Copy error details",
  closeButtonTitle: "Close"
}, B = {
  namespace: v,
  strings: b
}, L = "_alert_uuuo4_1", T = "_icon_uuuo4_8", x = "_close-icon_uuuo4_12", D = "_info_uuuo4_16", E = "_success_uuuo4_20", M = "_danger_uuuo4_24", A = "_warning_uuuo4_28", n = {
  alert: L,
  icon: T,
  closeIcon: x,
  info: D,
  success: E,
  danger: M,
  warning: A
}, W = {
  success: n.success,
  warning: n.warning,
  danger: n.danger,
  info: n.info
}, j = {
  success: /* @__PURE__ */ o(C, { className: n.icon }),
  danger: /* @__PURE__ */ o(h, { className: n.icon }),
  info: /* @__PURE__ */ o(I, { className: n.icon }),
  warning: /* @__PURE__ */ o(k, { className: n.icon })
};
function K(l) {
  const {
    name: i,
    className: u,
    type: t = "info",
    title: m,
    description: d,
    onCloseButtonClick: s,
    nonDismissable: g,
    debugMessage: e
  } = l, c = N(B), _ = a(
    () => {
      s && s(i);
    },
    [s, i]
  ), p = a(
    () => {
      e && navigator.clipboard.writeText(e);
    },
    [e]
  );
  return /* @__PURE__ */ o(
    y,
    {
      className: w(
        n.alert,
        W[t],
        u
      ),
      icons: j[t],
      heading: m,
      headingLevel: 5,
      withInternalPadding: !0,
      actions: g && /* @__PURE__ */ o(
        r,
        {
          name: void 0,
          onClick: _,
          variant: "tertiary-on-dark",
          title: c.closeButtonTitle,
          children: /* @__PURE__ */ o(f, { className: n.closeIcon })
        }
      ),
      withoutWrapInHeading: !0,
      footerActions: e && /* @__PURE__ */ o("div", { className: n.actions, children: /* @__PURE__ */ o(
        r,
        {
          name: void 0,
          onClick: p,
          variant: "tertiary-on-dark",
          children: c.alertCopyErrorDetails
        }
      ) }),
      children: d
    }
  );
}
export {
  K as default
};
