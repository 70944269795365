import "../../index40.css";
import { jsx as a, jsxs as m } from "react/jsx-runtime";
import { useMemo as c } from "react";
import { _ } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import y from "../../contexts/navigation-tab.js";
const d = "_navigation-tab-list_19mw3_1", l = "_primary_19mw3_5", p = "_content_19mw3_8", v = "_start-dummy-content_19mw3_12", u = "_end-dummy-content_19mw3_13", w = "_secondary_19mw3_20", b = "_tertiary_19mw3_36", C = "_step_19mw3_56", N = "_vertical_19mw3_70", t = {
  navigationTabList: d,
  primary: l,
  content: p,
  startDummyContent: v,
  endDummyContent: u,
  secondary: w,
  tertiary: b,
  step: C,
  vertical: N
};
function L(r) {
  const {
    children: e,
    variant: n = "primary",
    className: o,
    ...s
  } = r, i = c(
    () => ({
      variant: n
    }),
    [n]
  );
  return /* @__PURE__ */ a(y.Provider, { value: i, children: /* @__PURE__ */ m(
    "div",
    {
      ...s,
      className: _(
        o,
        t.navigationTabList,
        n === "primary" && t.primary,
        n === "secondary" && t.secondary,
        n === "tertiary" && t.tertiary,
        n === "step" && t.step,
        n === "vertical" && t.vertical
      ),
      role: "tablist",
      children: [
        /* @__PURE__ */ a("div", { className: t.startDummyContent }),
        /* @__PURE__ */ a("div", { className: t.content, children: e }),
        /* @__PURE__ */ a("div", { className: t.endDummyContent })
      ]
    }
  ) });
}
export {
  L as default
};
