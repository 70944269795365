import { jsx as u } from "react/jsx-runtime";
import N from "../../hooks/useBasicLayout/index.js";
function f(a) {
  const {
    actions: n,
    actionsContainerClassName: s,
    children: o,
    childrenContainerClassName: t,
    className: e,
    icons: i,
    iconsContainerClassName: c,
    spacing: r,
    withoutWrap: l
  } = a, {
    content: m,
    containerClassName: C
  } = N({
    actions: n,
    actionsContainerClassName: s,
    children: o,
    childrenContainerClassName: t,
    className: e,
    icons: i,
    iconsContainerClassName: c,
    spacing: r,
    withoutWrap: l
  });
  return /* @__PURE__ */ u("div", { className: C, children: m });
}
export {
  f as default
};
