import { useContext as n, useEffect as r } from "react";
import a from "../contexts/language.js";
function g(t) {
  const {
    strings: e,
    registerNamespace: s
  } = n(a);
  return r(() => {
    s(t.namespace, t.strings);
  }, [s, t]), e[t.namespace] ?? t.strings;
}
export {
  g as default
};
