import { useState as m, useEffect as f } from "react";
import { i as v } from "../type-BFTbooL7.js";
import { r as x } from "../string-COe5rUcp.js";
import { b as $ } from "../statistics-IyDYgeE6.js";
function S(c) {
  const [s] = m(() => `_${x()}`), {
    spacing: i,
    variant: t = "md",
    mode: e = "gap",
    inner: r
  } = c;
  return f(
    () => {
      const g = [
        "var(--go-ui-spacing-4xs)",
        "var(--go-ui-spacing-3xs)",
        "var(--go-ui-spacing-2xs)",
        "var(--go-ui-spacing-xs)",
        "var(--go-ui-spacing-sm)",
        "var(--go-ui-spacing-md)",
        "var(--go-ui-spacing-lg)",
        "var(--go-ui-spacing-xl)",
        "var(--go-ui-spacing-2xl)",
        "var(--go-ui-spacing-3xl)",
        "var(--go-ui-spacing-4xl)"
      ];
      let a = "0";
      if (i !== "none") {
        const d = {
          condensed: -3,
          compact: -2,
          cozy: -1,
          default: 0,
          comfortable: 1,
          relaxed: 2,
          loose: 3
        }, p = {
          xs: 3,
          sm: 4,
          md: 5,
          lg: 6,
          xl: 7
        }, u = r ? p[t] - 1 : p[t], l = d[i];
        a = g[$(
          u + l,
          0,
          g.length - 1
        )];
      }
      if (v(i))
        return;
      const n = document.createElement("style");
      if (document.head.appendChild(n), !n.sheet) {
        n.remove();
        return;
      }
      let o;
      return e === "gap" || e === "grid-gap" ? o = `gap: ${a};` : e === "padding-h" ? o = `padding-left: ${a}; padding-right: ${a};` : e === "padding-v" && (o = `padding-top: ${a}; padding-bottom: ${a};`), n.sheet.insertRule(`.${s} { ${o} }`), () => {
        n.remove();
      };
    },
    [i, t, e, r, s]
  ), s;
}
export {
  S as default
};
