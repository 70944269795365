import "../../index22.css";
import { jsx as o, jsxs as V } from "react/jsx-runtime";
import { useMemo as ae } from "react";
import { i as ce } from "../../type-BFTbooL7.js";
import { _ as t } from "../../cs-piwRuCFZ.js";
import le from "../DefaultMessage/index.js";
import de from "../FilterBar/index.js";
import me from "../Footer/index.js";
import fe from "../Header/index.js";
import m from "../../hooks/useSpacingTokens.js";
const he = "_container_z7c2q_1", pe = "_header_z7c2q_8", Ce = "_centered-header-description-container_z7c2q_11", _e = "_centered-description_z7c2q_16", ue = "_border_z7c2q_23", ge = "_content_z7c2q_29", we = "_pending-overlaid_z7c2q_32", Ne = "_message_z7c2q_37", ve = "_footer_z7c2q_43", ze = "_with-grid-view_z7c2q_47", qe = "_two-columns_z7c2q_57", De = "_three-columns_z7c2q_72", He = "_four-columns_z7c2q_86", Ve = "_five-columns_z7c2q_90", Ie = "_with-vertical-view_z7c2q_95", Me = "_with-overflow-in-content_z7c2q_102", e = {
  container: he,
  header: pe,
  centeredHeaderDescriptionContainer: Ce,
  centeredDescription: _e,
  border: ue,
  content: ge,
  pendingOverlaid: we,
  message: Ne,
  footer: ve,
  withGridView: ze,
  twoColumns: qe,
  threeColumns: De,
  fourColumns: He,
  fiveColumns: Ve,
  withVerticalView: Ie,
  withOverflowInContent: Me
}, Oe = {
  2: e.twoColumns,
  3: e.threeColumns,
  4: e.fourColumns,
  5: e.fiveColumns
};
function Be(I) {
  const {
    actions: u,
    actionsContainerClassName: M,
    children: f,
    childrenContainerClassName: O,
    className: T,
    containerRef: y,
    contentViewType: a = "default",
    ellipsizeHeading: F,
    filters: g,
    filterActions: P,
    footerActions: w,
    footerActionsContainerClassName: b,
    footerClassName: k,
    footerContent: N,
    footerContentClassName: G,
    footerIcons: v,
    headerClassName: S,
    headerDescription: r,
    withCenteredHeaderDescription: h,
    headerDescriptionContainerClassName: x,
    headerElementRef: A,
    heading: z,
    headingClassName: B,
    headingContainerClassName: R,
    headingDescription: q,
    headingDescriptionContainerClassName: W,
    headingLevel: j,
    headingSectionClassName: E,
    icons: D,
    iconsContainerClassName: L,
    numPreferredGridContentColumns: J = 2,
    spacing: n = "default",
    withHeaderBorder: K = !1,
    withFooterBorder: Q = !1,
    withOverflowInContent: U = !1,
    withInternalPadding: i = !1,
    withoutWrapInHeading: X = !1,
    withoutWrapInFooter: Y = !1,
    pending: s = !1,
    overlayPending: p = !1,
    empty: c = !1,
    errored: l = !1,
    filtered: C = !1,
    compactMessage: Z = !1,
    errorMessage: $,
    emptyMessage: ee,
    pendingMessage: ne,
    filteredEmptyMessage: oe
  } = I, _ = v || N || w, H = z || u || D || r || q, te = m({ spacing: n }), d = m({
    spacing: n,
    mode: "padding-h"
  }), re = m({
    spacing: n,
    mode: "padding-v"
  }), ie = m({
    spacing: n,
    mode: "gap",
    inner: !0
  }), se = ae(
    () => ce(r) ? null : h ? /* @__PURE__ */ o("div", { className: e.centeredDescription, children: r }) : r,
    [r, h]
  );
  return !H && !g && !f && !_ && !c && !s && !l && !C ? null : /* @__PURE__ */ V(
    "div",
    {
      ref: y,
      className: t(
        e.container,
        te,
        i && re,
        U && e.withOverflowInContent,
        a === "grid" && e.withGridView,
        a === "grid" && Oe[J],
        a === "vertical" && e.withVerticalView,
        T
      ),
      children: [
        H && /* @__PURE__ */ o(
          fe,
          {
            actions: u,
            className: t(
              e.header,
              i && d,
              S
            ),
            elementRef: A,
            actionsContainerClassName: M,
            ellipsizeHeading: F,
            heading: z,
            headingLevel: j,
            icons: D,
            iconsContainerClassName: L,
            childrenContainerClassName: t(
              h && e.centeredHeaderDescriptionContainer,
              x
            ),
            headingSectionClassName: E,
            headingClassName: B,
            headingContainerClassName: R,
            wrapHeadingContent: !X,
            headingDescription: q,
            headingDescriptionContainerClassName: W,
            spacing: n,
            children: se
          }
        ),
        K && /* @__PURE__ */ o("div", { className: e.border }),
        /* @__PURE__ */ o(
          de,
          {
            filters: g,
            filterActions: P,
            spacing: n,
            className: t(i && d)
          }
        ),
        (f || c || s || l || C) && /* @__PURE__ */ V(
          "div",
          {
            className: t(
              e.content,
              a !== "default" && ie,
              i && d,
              p && s && e.pendingOverlaid,
              O
            ),
            children: [
              /* @__PURE__ */ o(
                le,
                {
                  className: e.message,
                  pending: s,
                  filtered: C,
                  errored: l,
                  empty: c,
                  compact: Z,
                  overlayPending: p,
                  emptyMessage: ee,
                  filteredEmptyMessage: oe,
                  pendingMessage: ne,
                  errorMessage: $
                }
              ),
              !c && !l && (!s || p) && f
            ]
          }
        ),
        _ && Q && /* @__PURE__ */ o("div", { className: e.border }),
        _ && /* @__PURE__ */ o(
          me,
          {
            actions: w,
            icons: v,
            childrenContainerClassName: G,
            className: t(
              e.footer,
              i && d,
              k
            ),
            actionsContainerClassName: b,
            spacing: n,
            withoutWrap: Y,
            children: N
          }
        )
      ]
    }
  );
}
export {
  Be as default
};
